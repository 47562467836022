<template>
  <div class="content_body EntityApplyProduct" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" :model="searchForm" @keyup.enter.native="handleSearchEntityApplyProductClick">
            <el-form-item v-if="purchaseStorage.length>1" label="仓库/门店">
              <el-select v-model="searchForm.EntityID" :default-first-option="true" @change="handleSearchEntityApplyProductClick" @clear="handleSearchEntityApplyProductClick" clearable filterable placeholder="请选择仓库">
                <el-option v-for="item in purchaseStorage" :key="item.ID" :label="item.EntityName" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称">
              <el-input v-model="searchForm.Name" placeholder="输入产品名称、别名搜索" clearable @clear="handleSearchEntityApplyProductClick" @keyup.enter.native="handleSearchEntityApplyProductClick"></el-input>
            </el-form-item>
            <el-form-item label="制单日期">
              <el-date-picker v-model="searchForm.DateTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" clearable @change="searchDateChange"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearchEntityApplyProductClick" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button v-if="isAdd" type="primary" @click="addEntityApplyProductClick" size="small" v-prevent-click>要货申请</el-button>
        </el-col>
      </el-row>
    </div>

    <!-- 单据状态，10：待审核、20：待配送、30：待入库、40：已驳回、50：已完成、60：已取消 -->
    <el-tabs v-model="searchForm.BillStatus" type="border-card" @tab-click="tabsHandleClick">
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane name="10">
        <span slot="label">待审核
          <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus10 != 0" is-dot />
        </span>
      </el-tab-pane>
      <el-tab-pane name="20">
        <span slot="label">待配送
          <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus20 != 0" is-dot />
        </span>
      </el-tab-pane>
      <el-tab-pane name="30">
        <span slot="label">待入库
          <el-badge v-if="StatusNumberInfo  && StatusNumberInfo.BillStatus30 != 0" is-dot />
        </span>
      </el-tab-pane>
      <el-tab-pane name="40">
        <span slot="label">已驳回
          <el-badge v-if="StatusNumberInfo  && StatusNumberInfo.BillStatus40 != 0" is-dot />
        </span>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="50">
        <!-- <span slot="label">已完成<el-badge style="height: 30px;" v-model="StatusNumberInfo.BillStatus50" /><span v-if="StatusNumberInfo  && StatusNumberInfo.BillStatus50 != 0" class="color_red">({{StatusNumberInfo.BillStatus50}})</span> </span> -->
      </el-tab-pane>
      <el-tab-pane label="已关闭" name="60">
        <!-- <span slot="label">已关闭<span v-if="StatusNumberInfo  && StatusNumberInfo.BillStatus60 != 0" class="color_red">({{StatusNumberInfo.BillStatus60}})</span> </span> -->
      </el-tab-pane>
    </el-tabs>

    <el-table size="small" class="martp_10" :data="inventoryApplyList">
      <el-table-column prop="ID" label="单据号"></el-table-column>
      <el-table-column prop="InboundEntityName" label="申请仓库/门店"></el-table-column>
      <el-table-column prop="BillStatus" label="单据状态" :formatter="ApplyOrderBillStatusFormatter"></el-table-column>
      <el-table-column prop="TotalAmount" label="单据总额（元）">
        <template slot-scope="scope">￥{{ scope.row.TotalAmount | NumFormat }}</template>
      </el-table-column>
      <el-table-column prop="CreatedOn" label="制单时间"></el-table-column>
      <el-table-column prop="CreatedByName" label="申请人"></el-table-column>
      <el-table-column prop="Remark" label="备注信息" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <!-- 主动关闭 -->
          <el-button v-if="scope.row.BillStatus == '10' && isClose" type="danger" size="small" @click="closeEntityApplyProductClick(scope.row)" v-prevent-click>关 闭</el-button>
          <!-- 审核驳回关闭 -->
          <el-button v-if=" scope.row.BillStatus == '40' && isTurnClose" type="danger" size="small" @click="turnCloseEntityApplyProductClick(scope.row)" v-prevent-click>关 闭</el-button>

          <el-button v-if="scope.row.BillStatus == '10' && isCheck" class="martp_5" type="primary" size="small" @click="approvalEntityApplyProductDetail(scope.row)" v-prevent-click>审 批</el-button>
          <el-button v-if="scope.row.BillStatus == '20' && isDelivery" type="primary" size="small" @click="outboundEntityApplyProductClick(scope.row)" v-prevent-click>配 送</el-button>
          <el-button v-if="scope.row.BillStatus == '30' && isStorage" type="primary" size="small" @click="inboundEntityApplyProductClick(scope.row)" v-prevent-click>入 库</el-button>
          <el-button v-if="scope.row.BillStatus != '20' || scope.row.BillStatus != '30' || scope.row.BillStatus != '50'" type="primary" size="small" @click="checkEntityApplyProductDetail(scope.row)" v-prevent-click>详 情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="EntityApplyProductListHandleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>

    <!-- 新建门店要货  -->
    <el-dialog custom-class="entityApplyProductDialogClass" title="新建申请" :visible.sync="dialogVisible" width="1200px" @close="closeAddApplyProduct">
      <div class="tip marbm_10" style="margin-top:0">要货信息</div>
      <el-form class="entityApplyProductInfoFrom" :inline="true" :inline-message="true" label-width="auto" size="small" :model="entityApplyProduct" :rules="entityApplyProductRules" ref="entityApplyProductRef">
        <el-row>
          <el-col :span="12">
            <el-form-item label="申请仓库/门店：" prop="EntityID">
              <el-select size="small" value-key="ID" v-model="entityApplyProduct.EntityName" filterable placeholder="请选择仓库/门店" @change="handleSelectProductEntity">
                <el-option value-key="ID" v-for="item in purchaseStorage" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注信息：">
              <el-input type="textarea" style="width:300px" :autosize="{ minRows: 1, maxRows: 3 }" v-model="entityApplyProduct.Remark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip marbm_10 martp_10">产品明细</div>
        <el-row>
          <el-col :span="4">
            <el-button type="primary" size="small" @click="addProducts">添加产品</el-button>
            <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
          </el-col>
        </el-row>

        <el-table empty-text="暂无产品" size="small" class="martp_15" max-height="400px" :data="entityApplyProduct.Product" @selection-change="handleChangeSelectProduct">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="产品名称" prop="ProductName">

            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Product.' + scope.$index + '.ProductID'" :rules="entityApplyProductRules.ProductID">
                <el-select v-model="scope.row.ProductID" size="small" filterable remote reserve-keyword v-loadmore="loadMoreProduct" placeholder="请选择产品" :remote-method="searchProductListMethod" :loading="productLoading" @change="(val)=>handleSelectProduct(val,scope.row)" @focus="selectFocus" @clear="clearSelectProduct(scope.row)" :default-first-option="true" popper-class="EntityApplyProduct_custom_popper_class">
                  <el-option v-for="item in ProductList" :key="item.ID" :label="item.ProductName" :value="item.ID" >
                    <div class="dis_flex flex_dir_column pad_5_0">
                      <div>
                        <span>{{ item.ProductName }}</span>
                        <span class="color_gray marlt_5 font_12" v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLock" size="mini" type="warning" effect="dark">{{'盘点锁定'}}</el-tag>
                      </div>
                      <div :class="item.ID == scope.row.ID?'font_12 dis_flex  flex_x_between' : 'color_gray font_12 dis_flex flex_x_between'">
                        <span class="">{{ item.PCategoryName }}</span>
                        <span v-if="item.Specification">{{ item.Specification }}</span>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="Specification" label="产品规格">
            <template slot-scope="scope">{{scope.row.Specification}}</template>
          </el-table-column>
          <el-table-column prop="Quantity" label="可用库存">
            <template slot-scope="scope">
              {{scope.row.Quantity}} <span class="marlt_5">{{scope.row.miniUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="要货单位" prop="UnitName">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Product.' + scope.$index + '.UnitName'" :rules="entityApplyProductRules.UnitID">
                <el-select value-key="UnitID" v-model="scope.row.UnitName" size="small" filterable placeholder="请选择单位" @change="(val)=>handleSelectProductUnit(val,scope.row)" :default-first-option="true">
                  <el-option v-for="item in scope.row.Units" :key="item.UnitID" :label="item.UnitName" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="要货单位数量">
            <template slot-scope="scope">
              <el-form-item v-if="entityApplyProduct.Product.length > 0" :show-message="false" :prop="'Product.' + scope.$index + '.ApplyQuantity'" :rules="entityApplyProductRules.ApplyQuantity">
                <el-input v-model="scope.row.ApplyQuantity" size="small" placeholder="请输入要货数量" @input="changeApplyQuantity(scope.row)" validate-event v-enter-number2 v-enterInt min="0" type="number"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="最小包装单位数量" prop="miniUnitQuantity">
            <template slot-scope="scope">
              {{scope.row.miniUnitQuantity}} <span v-if="scope.row.miniUnitQuantity">{{scope.row.miniUnitName}}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="saveEntityApplyProductClick" :loading="modalLoading" size="small" v-prevent-click>提交申请</el-button>
      </div>
    </el-dialog>

    <!-- 门店要详情  -->
    <el-dialog custom-class="entityApplyProductDialogClass" title="要货详情" :visible.sync="applyDetaildialogVisible" width="1100px">
      <div v-if="applyDetailInfo" class="tip">基本信息 - <span class="font_weight_600">{{ApplyOrderBillStatusFormatter(applyDetailInfo)}}（{{applyDetailInfo.ID}}）</span></div>
      <el-form v-if="applyDetailInfo" class="entityApplyProductInfoFrom" label-width="110px" size="small" :model="applyDetailInfo">
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请仓库/门店：">{{applyDetailInfo.InboundEntityName}} </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人：">{{applyDetailInfo.CreatedByName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请时间：">{{applyDetailInfo.CreatedOn}}</el-form-item>
          </el-col>
        </el-row>
        <!-- 审批信息 -->
        <template v-if="applyDetailInfo.ApprovedByName">
          <!-- <div class="tip marbm_10">审批信息</div> -->
          <el-row>
            <el-col :span="8" v-if="applyDetailInfo.OutboundEntityID">
              <el-form-item label="发货仓库：">
                {{applyDetailInfo.OutboundEntityName}}
              </el-form-item>
            </el-col>
            <el-col :span="8" v-else>
              <el-form-item label="驳回原因：">
                {{applyDetailInfo.RejectReason}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="审批人：">
                {{applyDetailInfo.ApprovedByName}}
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="审批时间：">
                {{applyDetailInfo.ApprovedOn}}
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-row>
          <el-col v-if="applyDetailInfo.OutboundBillID&&isViewDeliveryBill" :span="8">
            <el-form-item label="配送出库单号：">
              {{applyDetailInfo.OutboundBillID}}
              <el-button type="text" @click="checkOutboundBillInfo">查看</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="applyDetailInfo.InboundBillID&&isViewStorageyBill">
            <el-form-item label="要货入库单号：">
              {{applyDetailInfo.InboundBillID}}
              <el-button type="text" @click="checkInbounBillInfo">查看</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注信息：">
              <div>{{applyDetailInfo.Remark}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip">产品明细</div>
        <el-table size="small" max-height="450px" :data="applyDetailInfo.Detail">
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="color_gray font_12"> ({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ApplyQuantity" label="要货数量">
            <template slot-scope="scope">
              <div>{{scope.row.ApplyQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.ApplyMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="applyDetailInfo.BillStatus == '30' || applyDetailInfo.BillStatus == '50'" prop="OutboundQuantity" label="实发数量">
            <template slot-scope="scope">
              <div>{{scope.row.OutboundQuantity}}{{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.OutboundMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="InboundQuantity" v-if="applyDetailInfo.BillStatus == '50'" label="实收数量">
            <template slot-scope="scope">
              <div>{{scope.row.InboundQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.InboundMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ApprovedPrice" label="单价" v-if="applyDetailInfo.BillStatus == '20' || applyDetailInfo.BillStatus == '30'  || applyDetailInfo.BillStatus == '50'">
            <template slot="header" slot-scope="scope">
              {{formatApprovedPriceTitle(scope.row,true)}}
            </template>
            <template slot-scope="scope">
              {{scope.row.ApprovedPrice | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column prop="TotalAmount" :formatter="formatterTotalAmount" label="小计(元)" v-if="applyDetailInfo.BillStatus == '20' || applyDetailInfo.BillStatus == '30'  || applyDetailInfo.BillStatus == '50'">
            <template slot-scope="scope">
              {{formatterTotalAmount(scope.row) | NumFormat}}
            </template>
          </el-table-column>
        </el-table>
      </el-form>

    </el-dialog>

    <!-- 门店要货审批  -->
    <el-dialog custom-class="entityApplyProductDialogClass" title="要货审批" :visible.sync="approvalDetaildialogVisible" width="1100px">
      <div class="tip" style="margin-top:0">基本信息</div>
      <!-- :inline="true"   -->
      <el-form v-if="applyDetailInfo" class="entityApplyProductInfoFrom" label-width="110px" size="small" :model="applyDetailInfo" :rules="approveEntityRules" ref="approvalDetailRef">
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请仓库/门店：" prop="OutboundEntityName"> {{applyDetailInfo.InboundEntityName}} </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="要货状态：" prop="BillStatus"> {{ApplyOrderBillStatusFormatter(applyDetailInfo)}} </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人："> {{applyDetailInfo.CreatedByName}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="申请日期：">
              {{applyDetailInfo.CreatedOn}}
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注信息：">
              <div>{{applyDetailInfo.Remark}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货仓库：" prop="EntityID" :inline-message="true">
              <el-select size="small" value-key="ID" v-model="applyDetailInfo.EntityItem" filterable placeholder="请选择发货仓库" @change="deliveryHandleSelectProductEntity">
                <el-option value-key="ID" v-for="item in OutboundEntitys" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

        <div class="tip  martp_10">产品明细</div>

        <el-table size="small" max-height="400px" :data="applyDetailInfo.Detail">
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="color_gray font_12"> ({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="StockQuantity" label="实物库存">
            <template slot-scope="scope">{{scope.row.StockQuantity}} {{scope.row.MinimumUnitName}}</template>
          </el-table-column>
          <el-table-column prop="ApplyQuantity" label="要货数量">
            <template slot-scope="scope">
              <div>{{scope.row.ApplyQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.ApplyMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>

          <el-table-column prop="ApproveQuantity" label="预配数量">
            <template slot="header">
              预配数量
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>预计可以给仓库/门店配送的数量，仓库实际发货以此为依据进行发货</p>
                <el-button type="text" style="color: #dcdfe6;padding:0px" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-form-item label-width="0px" :show-message="false" :prop="'Detail.' + scope.$index + '.ApproveQuantity'" :rules="approveEntityRules.ApproveQuantity">
                <el-input v-if="applyDetailInfo.EntityID" v-model="scope.row.ApproveQuantity" class="input_type" size="small" @input="changeApprovalQuantity(scope.row)" validate-event v-enter-number2 v-enterInt min="0" type="number">
                  <template slot="append">{{scope.row.UnitName}}</template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ApprovedPrice" label="配送单价(元)">
            <template slot-scope="scope">
              <el-form-item label-width="0px" :show-message="false" :prop="'Detail.' + scope.$index + '.ApprovedPrice'" :rules="approveEntityRules.ApprovedPrice">
                <el-input v-if="applyDetailInfo.EntityID" v-model="scope.row.ApprovedPrice" class="input_type" size="small" @input="changeApprovalPrice(scope.row)" validate-event v-enter-number2 min="0" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ApprovedTotalAmount" label="小计(元)">
            <template slot-scope="scope">
              {{scope.row.ApprovedTotalAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column v-if="applyDetailInfo.EntityID" label="预配最小包装数量">
            <template slot-scope="scope">{{scope.row.MinimumUnitAmount * scope.row.ApproveQuantity}} {{scope.row.MinimumUnitName}}</template>
          </el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button @click="approvalDetaildialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="danger" @click="approvalEntityApplyProductClick(false)" size="small" v-prevent-click plain>审核驳回</el-button>
        <el-button type="primary" @click="approvalEntityApplyProductClick(true)" :loading="approvedPaasLoading" size="small" v-prevent-click>审核通过</el-button>
      </div>
    </el-dialog>

    <!-- 驳回审核 -->
    <el-dialog width="600px" :visible.sync="finalRejectionDialogVisible" title="审核驳回">
      <el-input type="textarea" :rows="4" v-model="finalRejection" placeholder="请输入备注内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="finalRejectionDialogVisible = false" size="small">取消</el-button>
        <el-button type="danger" @click="finalRejectApprovedClick" :loading="approvedRefuseLoading" size="small">驳回审核</el-button>
      </span>
    </el-dialog>

    <!-- 门店要货 配送  -->
    <el-dialog custom-class="entityApplyProductDialogClass" title="配送出库" :visible.sync="outboundDetaildialogVisible" width="1100px">
      <div class="tip">基本信息</div>
      <el-form v-if="applyDetailInfo" :inline="true" :inline-message="true" class="entityApplyProductInfoFrom" label-width="110px" size="small" :model="applyDetailInfo" :rules="approveEntityRules" ref="outboundDetailRef">
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请仓库/门店：" prop="InboundEntityName"> {{applyDetailInfo.InboundEntityName}} </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人："> {{applyDetailInfo.CreatedByName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期：">
              {{applyDetailInfo.CreatedOn}}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="发货仓库：" prop="OutboundEntityName">
              {{applyDetailInfo.OutboundEntityName}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审批人：" prop="ApprovedByName">
              {{applyDetailInfo.ApprovedByName}}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="审批日期：" prop="InDate">
              {{applyDetailInfo.ApprovedOn}}
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注信息：" class="padbm_10 padtp_5">
              <el-input  type="textarea" style="width:280px" :autosize="{ minRows: 1, maxRows: 3 }" v-model="applyDetailInfo.outRemark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip">产品明细</div>
        <el-table size="small" max-height="500px" :data="applyDetailInfo.Detail">
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="color_gray font_12"> ({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="StockQuantity" label="可用库存">
            <template slot-scope="scope">
              {{scope.row.StockQuantity}} <span>{{scope.row.MinimumUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ApplyQuantity" label="要货数量">
            <template slot-scope="scope">
              <div>{{scope.row.ApplyQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.ApplyMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ApproveQuantity" label="预配数量">
            <template slot="header">
              预配数量
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>预计可以给仓库/门店配送的数量，仓库实际发货以此为依据进行发货</p>
                <el-button type="text" style="color: #dcdfe6;padding:0px" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <div>{{scope.row.ApproveQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.ApproveMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="OutboundQuantity" label="实发数量">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.OutboundQuantity'" :rules="approveEntityRules.OutboundQuantity">
                <el-input v-model="scope.row.OutboundQuantity" size="small" class="input_type" placeholder="请输入实发数量" @input="changeOutboundQuantity(scope.row)" validate-event v-enter-number2 v-enterInt min="0" type="number" :disabled="scope.row.OutboundIsLock">
                  <template slot="append">{{scope.row.UnitName}}</template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="stockoutQuantity" label="缺货数量">
            <template slot="header">
              缺货数量
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>缺货数量 = 预配数量 - 实发数量，结果最小为0</p>
                <el-button type="text" style="color: #dcdfe6;padding:0px" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <div>{{scope.row.stockoutQuantity || 0}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{parseFloat(scope.row.stockoutQuantity || 0) * parseFloat(scope.row.MinimumUnitAmount || 0)}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>

          <el-table-column label="实发最小包装数量">
            <template slot-scope="scope">
              <div v-if="!scope.row.OutboundIsLock"> 
                {{parseFloat(scope.row.MinimumUnitAmount || 0)  * parseFloat(scope.row.OutboundQuantity || 0)}} {{scope.row.MinimumUnitName}}
              </div>
              <el-tag v-else size="mini" type="warning">{{'盘点锁定'}}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer">
        <el-button @click="outboundDetaildialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="saveOutboundEntityApplyProductClick" :loading="outboundLoading" size="small" v-prevent-click>配送出库</el-button>
      </div>
    </el-dialog>

    <!-- 门店要货 入库  -->
    <el-dialog custom-class="entityApplyProductDialogClass" title="要货入库" :visible.sync="inboundDetaildialogVisible" width="1100px">
      <div class="tip">基本信息</div>
      <el-form v-if="applyDetailInfo" :inline="true" :inline-message="true" class="entityApplyProductInfoFrom" label-width="110px" size="small" :model="applyDetailInfo" :rules="approveEntityRules" ref="InboundDetailRef">
        <!-- 申请信息 -->
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请仓库/门店：" prop="InboundEntityName"> {{applyDetailInfo.InboundEntityName}} </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人："> {{applyDetailInfo.CreatedByName}}</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="入库备注信息：">
              <el-input  type="textarea"  :autosize="{ minRows: 1, maxRows: 3 }" v-model="applyDetailInfo.inRemark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip martp_10">产品明细</div>
        <el-table class="martp_10" size="small" max-height="450px" :data="applyDetailInfo.Detail">
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="color_gray font_12"> ({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ApplyQuantity" label="要货数量">
            <template slot-scope="scope">
              <div>{{scope.row.ApplyQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.ApplyMinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="OutboundQuantity" label="实发数量">
            <template slot-scope="scope">
              <div> {{scope.row.OutboundQuantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.OutboundQuantity * scope.row.MinimumUnitAmount}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="InboundQuantity" label="实收数量">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.InboundQuantity'" :rules="approveEntityRules.InboundQuantity">
                <el-input v-model="scope.row.InboundQuantity" class="input_type" size="small" placeholder="请输入入库数量" @input="changeInboundQuantity(scope.row)" validate-event v-enter-number2 v-enterInt min="0" type="number" :disabled="scope.row.InboundIsLock">
                  <template slot="append">{{scope.row.UnitName}}</template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ApprovedPrice" label="配送单价(元)">
            <template slot-scope="scope">
              {{scope.row.ApprovedPrice | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column label="小计(元)">
            <template slot-scope="scope">
              {{scope.row.ApprovedPrice * scope.row.InboundQuantity | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column prop="stockoutQuantity" label="实收最小包装数量">
            
            <template slot-scope="scope">
              <div v-if="!scope.row.InboundIsLock"> 
                {{parseFloat(scope.row.MinimumUnitAmount || 0)  * parseFloat(scope.row.InboundQuantity || 0)}} {{scope.row.MinimumUnitName}}
              </div>
              <el-tag v-else size="mini" type="warning">{{'盘点锁定'}}</el-tag>
            </template>
          </el-table-column>

        </el-table>
      </el-form>

      <div slot="footer">
        <el-button @click="inboundDetaildialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="saveInboundEntityApplyProductClick" :loading="outboundLoading" size="small" v-prevent-click>确认入库</el-button>
      </div>
    </el-dialog>

    <!-- 入库 详情 -->
    <el-dialog custom-class="entityApplyProductDialogClass" title="要货入库详情" :visible.sync="InboundInfoDialogVisible" width="1000px">
      <div class="tip">基本信息</div>
      <el-form class="entityApplyProductInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="InboundInfo">
        <el-row>
          <el-col :span="8">
            <el-form-item label="入库仓库：">{{InboundInfo.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人：">{{InboundInfo.CreatedByName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库时间：">{{InboundInfo.InDate}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="备注信息：">{{InboundInfo.Remark}}</el-form-item>
          </el-col>
        </el-row>

        <div class="tip">产品明细</div>
        <el-table size="small" max-height="450px" :data="InboundInfo.Detail">
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="color_gray font_12"> ({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column label="入库数量" prop="Quantity">
            <template slot-scope="scope">
              <div>{{scope.row.Quantity || 0}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.MinimumUnitQuantity || 0}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>

        </el-table>

      </el-form>
    </el-dialog>

    <!-- 详情-->
    <el-dialog custom-class="entityApplyProductDialogClass" title="配送出库详情" :visible.sync="OutboundInfoDialogVisible" width="1000px">
      <div class="tip">基本信息</div>
      <el-form class="entityApplyProductInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="OutboundInfo">
        <el-row>
          <el-col :span="8">
            <el-form-item label="出库仓库：">{{OutboundInfo.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人：">{{OutboundInfo.CreatedByName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库时间：" prop="OutDate">{{OutboundInfo.OutDate}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="备注信息：">{{OutboundInfo.Remark}}</el-form-item>
          </el-col>
        </el-row>
        <div class="tip">产品明细</div>
        <el-table size="small" max-height="400px" :data="OutboundInfo.Detail">
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="color_gray font_12"> ({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="出库数量">
            <template slot-scope="scope">
              <div>{{scope.row.Quantity || 0}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.MinimumUnitQuantity || 0}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
        </el-table>

      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import permission from "@/components/js/permission.js";
import APIStorage from "@/api/PSI/Purchase/storage";
// import APIInquire from  "@/api/PSI/Inventory/Inquire";
import APIInventory from "@/api/PSI/Inventory/inventoryDetail";
import APIPSIApplyProduct from "@/api/PSI/Purchase/entityApplyProduct";
import APIInbound from "@/api/PSI/Inventory/inventoryProductInbound";
import APIOutbound from "@/api/PSI/Inventory/inventoryProductOutbound";

import dateUtil from "@/components/js/date";
import Templet1 from "../../templet/templet1.vue";

var Enumerable = require("linq");

export default {
  name: "EntityApplyProduct",
  /**  Vue 实例的数据对象**/
  data() {
    return {
      /**  权限功能按钮   */
      isAdd: false, // 新建要货
      isCheck: false, // 审核单据
      isClose: false, // 关闭待审核单据
      isTurnClose: false, // 关闭已驳回单据单据
      isDelivery: false, // 配送出库
      isStorage: false, // 要货入库

      // 一下两个功能权限暂时不写 【功能模块没有写】
      isViewDeliveryBill: false, // 查看配送出库单
      isViewStorageyBill: false, // 查看要货入库单

      loading: false,
      approvedLoading: false,
      outboundLoading: false,
      modalLoading: false,

      approvedRefuseLoading: false, //审批驳货
      approvedPaasLoading: false, // 审批通过

      dialogVisible: false,
      applyDetaildialogVisible: false, //要货详情
      approvalDetaildialogVisible: false, // 审批
      finalRejectionDialogVisible: false,
      outboundDetaildialogVisible: false, // 审批出库
      inboundDetaildialogVisible: false, //入库

      selectProductDialogVisible: false,

      InboundInfoDialogVisible: false, //入库详情
      OutboundInfoDialogVisible: false, // 出库
      productLoading: false,

      removeDisabled: true, //选中产品删除按钮是否禁用
      multipleProducts: [], // 已选中的将要删除的产品
      // 列表筛选条件
      searchForm: {
        ID: "",
        Name: "", //产品名称
        DateTime: "",
        BillStatus: "0", //单据状态，10：待审核、20：待配送、30：待入库、40：已驳回、50：已完成、60：已取消
        EntityID: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      searchProductName: "",
      //创建要货 验证规则
      entityApplyProduct: {
        InDate: dateUtil.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"), //入库时间
        EntityID: "", //仓库ID
        EntityName: "",
        Remark: "", // 备注
        Product: [], // 产品列表
      },
      entityApplyProductRules: {
        EntityID: [
          {
            required: true,
            message: "请选择仓库/门店",
            trigger: ["blur", "change"],
          },
        ],
        ApplyQuantity: [{ required: true, trigger: ["blur", "change"] }],
        ProductID: [{ required: true, trigger: ["blur", "change"] }],
        UnitID: [{ required: true, trigger: ["blur", "change"] }],
      }, //产品验证规则

      // 要货列表
      inventoryApplyList: [],
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      purchaseStorage: [], //仓库列表
      OutboundEntitys: [], // 审批要货仓库 排除入库仓
      // 要货产品信息
      ProductList: [],
      ProductListTotal: 0,
      productPageNum: 1,
      selectProductList: [],
      ProductPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      applyDetailInfo: null,
      approveEntityRules: "",
      finalRejection: "", //驳回原因

      StatusNumberInfo: "", // 个状态数量
      InboundInfo: "",
      OutboundInfo: "",
    };
  },
  /**  路由  */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      /**  新建要货  */
      vm.isAdd = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Add"
      );
      /**  审核单据  */
      vm.isCheck = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Check"
      );
      /** 关闭待审核单据 */
      vm.isClose = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Close"
      );
      /** 关闭已驳回单据 */
      vm.isTurnClose = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-RejectClose"
      );
      /**  配送出库 */
      vm.isDelivery = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Delivery"
      );
      /**  要货入库 */
      vm.isStorage = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Storage"
      );
      /**  查看配送出库单 */
      vm.isViewDeliveryBill = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-ViewDeliveryBill"
      );
      /**  查看要货入库单  */
      vm.isViewStorageyBill = permission.permission(
        to.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-ViewStorageyBill"
      );
    });
  },
  /**  方法集合  */
  methods: {
    /**  点击搜索  */
    handleSearchEntityApplyProductClick() {
      let that = this;
      // that.searchForm.BillStatus = "0";
      that.paginations.page = 1;
      that.getInventoryApplyListNetwork();
    },
    /**  时间修改  */
    searchDateChange() {
      let that = this;
      that.handleSearchEntityApplyProductClick();
    },

    /** 要货列表 分页切换  */
    EntityApplyProductListHandleCurrentChange(page) {
      let that = this;
      that.paginations.paginations = page;
      that.getInventoryApplyListNetwork();
    },
    /**  订单状态数据格式化  */
    ApplyOrderBillStatusFormatter(row) {
      // 10：待审核、20：待配送、30：待入库、40：已驳回、50：已完成、60：已取消
      if (!row) {
        return "";
      }
      switch (row.BillStatus) {
        case "10":
          return "待审核";
          break;
        case "20":
          return "待配送";
          break;
        case "30":
          return "待入库";
          break;
        case "40":
          return "已驳回";
          break;
        case "50":
          return "已完成";
          break;
        case "60":
          return "已取消";
          break;
      }
    },
    /**  点击tabs 切换  */
    tabsHandleClick() {
      var that = this;
      that.paginations.page = 1;
      that.getInventoryApplyListNetwork();
    },

    /**  添加要货申请  */
    addEntityApplyProductClick() {
      var that = this;

      // that.$refs["entityApplyProductRef"].resetFields()
      that.entityApplyProduct = {
        InDate: dateUtil.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"), //入库时间
        EntityID: "", //仓库ID
        EntityName: "",
        Remark: "", // 备注
        Product: [], // 产品列表
      };
      if (that.purchaseStorage.length == 1) {
        that.entityApplyProduct.EntityID = that.purchaseStorage[0].ID;
        that.entityApplyProduct.EntityName = that.purchaseStorage[0].EntityName;
      }
      that.dialogVisible = true;
    },
    /** 关闭新增弹窗的回调   */
    closeAddApplyProduct() {
      let that = this;
      that.$refs["entityApplyProductRef"].clearValidate();
      if (that.$refs.multipleTable) {
        that.$refs.multipleTable.clearSelection();
      }
    },
    /**  选择 要货仓库  */
    handleSelectProductEntity(row) {
      let that = this;
      that.entityApplyProduct.EntityID = row.ID;
      that.entityApplyProduct.Product = [];
    },
    /**  要货添加产品 -- 新增 */
    addProducts() {
      let that = this;
      that.ProductPaginations.page = 1;

      that.$refs["entityApplyProductRef"].validateField("EntityID", (valid) => {
        if (!valid) {
          that.entityApplyProduct.Product.push({
            ProductID: "",
            ProductName: "",
            Alias: "",
            Unit: "",
            UnitID: "",
            UnitName: "",
            Quantity: "",
            ApplyQuantity: "",
          });
          // that.get_stock_list_entityProductListNetwork();
          // that.selectProductDialogVisible = true;
        }
      });
    },

    /**  下拉选择产品  */
    handleSelectProduct(item_ID, row) {
      console.log(
        "🚀 ~ file: entityApplyProduct.vue ~ line 976 ~ handleSelectProduct ~ row",
        row
      );
      var that = this;
      row.ApplyQuantity = "";
      let item = Enumerable.from(that.ProductList).firstOrDefault((i) => {
        return item_ID == i.ID;
      }, {});
      row.ProductID = item.ID;
      row.ProductName = item.ProductName;
      row.Units = item.Unit;
      row.Specification = item.Specification;
      row.Quantity = item.Quantity;
      row.miniUnitQuantity = "";

      let defaultUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsDefautSendReceive;
      }, -1);
      let miniUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsMinimumUnit;
      }, -1);
      if (defaultUnit != -1) {
        row.UnitID = defaultUnit.UnitID;
        row.UnitName = defaultUnit.UnitName;
        row.miniAmount = defaultUnit.Amount;
      }
      if (miniUnit != -1) {
        row.miniUnitName = miniUnit.UnitName;
        row.miniUnitID = miniUnit.UnitID;
      }
    },
    /** 获取焦点   */
    selectFocus() {
      let that = this;
      that.ProductList = [];
      that.searchProductListMethod("");
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.ProductList = [];
      that.get_stock_list_entityProductListNetwork(query);
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.ProductListTotal > that.ProductList.length) {
        that.productPageNum++;
        that.get_stock_list_entityProductListNetwork();
      }
    },
    /**   选择单位 */
    handleSelectProductUnit(val, row) {
      let that = this;
      row.ApplyQuantity = "";
      row.UnitID = val.UnitID;
      row.UnitName = val.UnitName;
      row.miniAmount = val.Amount;
    },

    /**  修改要货数量  */
    changeApplyQuantity(row) {
      row.ApplyQuantity = Math.floor(row.ApplyQuantity);
      row.miniUnitQuantity =
        parseFloat(row.ApplyQuantity) * parseFloat(row.miniAmount);
      // if (row.ApplyQuantity > row.Quantity) {
      //     row.ApplyQuantity = row.Quantity
      //     that.$message.error({
      //       message:"库存不足",
      //       duration:2000
      //     })
      //   return
      // }
    },
    /**  产品 --- 列表切换分页 */
    handleProductCurrentChange(page) {
      let that = this;
      that.ProductPaginations.page = page;
      that.get_stock_list_entityProductListNetwork();
    },

    /**  批量删除  */
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (var i = 0; i < that.entityApplyProduct.Product.length; i++) {
          that.multipleProducts.forEach(function (item) {
            if (that.entityApplyProduct.Product[i] == item) {
              that.entityApplyProduct.Product.splice(i, 1);
            }
          });
        }
      }
    },

    /** 选中 产品 执行删除   */
    handleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },

    /** 保存要货申请信息   */
    saveEntityApplyProductClick() {
      let that = this;
      if (that.entityApplyProduct.Product.length == 0) {
        that.$message.error({
          message: "请选择要货产品",
          duration: 2000,
        });
        return;
      }
      that.$refs["entityApplyProductRef"].validate((valid) => {
        if (valid) {
          that.setInventoryApplyCreateNetwork();
        }
      });
    },
    /**  关闭要货   */
    closeEntityApplyProductClick(row) {
      var that = this;
      that
        .$confirm("关闭要货后将无法继续要货, 确定要关闭吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.setInventoryProductCancelNetwork(row.ID);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /**  审核驳回关闭  */
    turnCloseEntityApplyProductClick(row) {
      var that = this;
      that
        .$confirm("确定要关闭吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.setinventoryProductCancelRejectApplylNetwork(row.ID);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /**  查看要货详情  */
    checkEntityApplyProductDetail(row) {
      let that = this;
      that.getinventoryApplyInfoNetwork(row.ID, 0);
    },

    /**  单价 标题动态修改  */
    formatApprovedPriceTitle(row, isPrice) {
      var that = this;
      switch (that.applyDetailInfo.BillStatus) {
        case "20":
          return isPrice ? "审核单价" : "小计(元)";
        case "30":
          return isPrice ? "配送单价" : "小计(元)";
        case "50":
          return isPrice ? "配送单价" : "小计(元)";
        default:
          return "";
      }
    },
    /**  要货金额统计  */
    formatterTotalAmount(row) {
      let that = this;
      let TotalAmount = "";
      switch (that.applyDetailInfo.BillStatus) {
        case "20":
          TotalAmount = row.ApprovedTotalAmount;
          break;

        case "30":
          TotalAmount = row.OutboundTotalAmount;
          break;

        case "40":
        case "50":
          TotalAmount = row.InboundTotalAmount;
          break;

        default:
          TotalAmount = "";
          break;
      }
      // NumFormat
      return TotalAmount;
    },
    /** 要货 审批 详情  */
    approvalEntityApplyProductDetail(row) {
      let that = this;
      that.getinventoryApplyInfoNetwork(row.ID, 1);
    },
    /**  审批 发货仓库选择  */
    deliveryHandleSelectProductEntity(row) {
      let that = this;
      let ProductIDs = Enumerable.from(that.applyDetailInfo.Detail)
        .select((i) => i.ProductID)
        .toArray();
      that.applyDetailInfo.EntityID = row.ID;
      that.getinventoryProductStockNetwork(
        ProductIDs,
        that.applyDetailInfo.EntityID
      );
    },
    /**  修改审批通过数量  */
    changeApprovalQuantity(row) {
      let that = this;
      row.ApproveQuantity = Math.floor(row.ApproveQuantity);
      // 下标
      let indexOf = that.applyDetailInfo.Detail.indexOf(row);
      // 排除 当前项的  已审批数量
      let totalQuantity = Enumerable.from(that.applyDetailInfo.Detail)
        .where((i, index) => {
          return i.ProductID == row.ProductID && index != indexOf;
        })
        .sum((i) => Number(i.ApproveQuantity) * Number(i.MinimumUnitAmount));
      // 剩余的最小单位数量
      let balanceMiniQuantity =
        parseFloat(row.StockQuantity) - parseFloat(totalQuantity);
      // 转换大单位数量
      let balanceQuantity = Math.floor(
        balanceMiniQuantity / row.MinimumUnitAmount
      );
      // 临时值  申请数量是否大于当前库存数量  单位按照申请单位计算
      let tempQuantity =
        row.ApplyQuantity > balanceQuantity
          ? balanceQuantity
          : row.ApplyQuantity;

      if (row.ApproveQuantity > tempQuantity) {
        row.ApproveQuantity = tempQuantity;
        that.$message.error({
          message: "预配数量超出实物库存数量",
          duration: 2000,
        });
      }

      if (row.ApprovedPrice) {
        row.ApprovedTotalAmount = parseFloat(
          parseFloat(row.ApprovedPrice) * parseFloat(row.ApproveQuantity)
        ).toFixed(2);
      }
    },
    /**  修改审核 配送单价  */
    changeApprovalPrice(row) {
      if (row.ApprovedPrice && row.ApproveQuantity) {
        row.ApprovedTotalAmount = parseFloat(
          parseFloat(row.ApprovedPrice) * parseFloat(row.ApproveQuantity)
        ).toFixed(2);
      }
    },

    /**  审核通过 驳回  */
    approvalEntityApplyProductClick(isPass) {
      let that = this;
      if (isPass) {
        // 通过
        that.$refs["approvalDetailRef"].validate((valid, obj) => {
          if (valid) {
            let ApprovedTotalAmount = Enumerable.from(
              that.applyDetailInfo.Detail
            ).sum((i) => Number(i.ApprovedTotalAmount));

            let InventoryApplyDetail = Enumerable.from(
              that.applyDetailInfo.Detail
            )
              .select((i) => ({
                ID: i.ID,
                ApproveQuantity: i.ApproveQuantity,
                ApprovedPrice: i.ApprovedPrice,
                ApprovedTotalAmount: i.ApprovedTotalAmount || 0,
                ApproveMinimumUnitQuantity:
                  parseFloat(i.ApproveQuantity || 0) *
                  parseFloat(i.MinimumUnitAmount || 0), // 最小单位数量
              }))
              .toArray();

            var approvedParams = {
              ID: that.applyDetailInfo.ID,
              BillStatus: "20",
              ApprovedTotalAmount: ApprovedTotalAmount,
              OutboundEntityID: that.applyDetailInfo.EntityID,
              InventoryApplyDetail: InventoryApplyDetail,
            };
            that.approvedPaasLoading = true;
            that.setinventoryApplyApprovedNetwork(approvedParams);
          }
        });
      } else {
        // 驳回
        that.finalRejectionDialogVisible = true;
      }
    },

    /**  审核驳回  */
    finalRejectApprovedClick() {
      let that = this;
      var approvedParams = {
        ID: that.applyDetailInfo.ID,
        BillStatus: "40",
        RejectReason: that.finalRejection,
        ApprovedTotalAmount: "",
        OutboundEntityID: "",
        InventoryApplyDetail: [],
      };
      that.approvedRefuseLoading = true;
      that.setinventoryApplyApprovedNetwork(approvedParams);
    },

    /**  配送 详情  */
    outboundEntityApplyProductClick(row) {
      let that = this;
      that.getinventoryApplyInfoNetwork(row.ID, 2);
    },

    /** 配送修改数量  */
    changeOutboundQuantity(row) {
      let that = this;
      row.OutboundQuantity = Math.floor(row.OutboundQuantity);
      let tempQuantity = row.StockQuantity > row.ApproveQuantity?row.ApproveQuantity:row.StockQuantity;
      if (row.OutboundQuantity > tempQuantity) {
        row.OutboundQuantity = tempQuantity;
        row.stockoutQuantity = tempQuantity - row.OutboundQuantity;
        that.$message.error({
          message: "实发数量不能超过预配数量和可用库存数量",
          duration: 2000,
        });
        return;
      }
      row.stockoutQuantity = row.ApproveQuantity - row.OutboundQuantity;
      row.OutboundTotalAmount =
        parseFloat(row.OutboundQuantity) * parseFloat(row.ApprovedPrice);
    },
    /** 创建配送出库   */
    saveOutboundEntityApplyProductClick() {
      let that = this;
      let isLock = Enumerable.from(that.applyDetailInfo.Detail).contains(true,val=>{ return val.OutboundIsLock});
      if(isLock){
        that.$message.error({
          message:"存在盘点锁定的产品",
          duration:2000,
        })
        return
      }

      that.$refs["outboundDetailRef"].validate((valid) => {
        if (valid) {
          let InventoryApplyDetail = Enumerable.from(
            that.applyDetailInfo.Detail
          )
            .select((i) => ({
              ID: i.ID,
              OutboundQuantity: i.OutboundQuantity,
              OutboundPrice: i.OutboundPrice,
              OutboundTotalAmount: i.OutboundTotalAmount,
              OutboundMinimumUnitQuantity:
                parseFloat(i.OutboundQuantity) *
                parseFloat(i.MinimumUnitAmount),
            }))
            .toArray();

          let OutboundTotalAmount = Enumerable.from(
            that.applyDetailInfo.Detail
          ).sum((i) => Number(i.OutboundTotalAmount));

          let params = {
            ID: that.applyDetailInfo.ID,
            OutboundTotalAmount: OutboundTotalAmount,
            InventoryApplyDetail: InventoryApplyDetail,
            Remark:that.applyDetailInfo.outRemark,
          };

          that.setinventoryApplyOutboundNetwork(params);
        }
      });
    },

    /**  入库  */
    inboundEntityApplyProductClick(row) {
      let that = this;
      that.getinventoryApplyInfoNetwork(row.ID, 3);
    },
    /**  修改入库数量  */
    changeInboundQuantity(row) {
      let that = this;
      row.InboundQuantity = Math.floor(row.InboundQuantity);

      if (row.InboundQuantity > row.OutboundQuantity) {
        row.InboundQuantity = row.OutboundQuantity;
        // row.stockoutQuantity = row.ApproveQuantity - row.OutboundQuantity
        that.$message.error({
          message: "实收数量不能超过实发数量",
          duration: 2000,
        });
        return;
      }
      // row.stockoutQuantity = row.ApproveQuantity - row.OutboundQuantity
      row.InboundTotalAmount =
        parseFloat(row.InboundQuantity) * parseFloat(row.InboundPrice);
    },
    /**  保存入库  */
    saveInboundEntityApplyProductClick() {
      let that = this;
      let isLock = Enumerable.from(that.applyDetailInfo.Detail).contains(true,val=>{ return val.InboundIsLock});
      if(isLock){
        that.$message.error({
          message:"存在盘点锁定的产品",
          duration:2000,
        })
        return
      }
      that.$refs["InboundDetailRef"].validate((valid) => {
        if (valid) {
          let InventoryApplyDetail = Enumerable.from(
            that.applyDetailInfo.Detail
          )
            .select((i) => ({
              ID: i.ID,
              InboundQuantity: i.InboundQuantity,
              InboundPrice: i.InboundPrice,
              InboundTotalAmount: i.InboundTotalAmount,
              InboundMinimumUnitQuantity:
                parseFloat(i.InboundQuantity) * parseFloat(i.MinimumUnitAmount),
            }))
            .toArray();

          let InboundTotalAmount = Enumerable.from(
            that.applyDetailInfo.Detail
          ).sum((i) => Number(i.InboundTotalAmount));

          let params = {
            ID: that.applyDetailInfo.ID,
            InboundTotalAmount: InboundTotalAmount,
            InventoryApplyDetail: InventoryApplyDetail,
            Remark:that.applyDetailInfo.inRemark
          };

          that.setinventoryApplyInboundNetwork(params);
        }
      });
    },

    /** 查看出库明细   */
    checkOutboundBillInfo() {
      let that = this;
      let parmas = {
        ID: that.applyDetailInfo.OutboundBillID,
        InventoryType: "配送出库",
      };
      that.get_info_ProductInventoryOutbound_netWork(parmas);
    },
    /**  查看入库明细  */
    checkInbounBillInfo() {
      let that = this;
      let parmas = {
        ID: that.applyDetailInfo.InboundBillID,
        InventoryType: "要货入库",
      };
      that.get_info_inventoryProductInbound_netWork(parmas);
    },

    /**  ==========================================================================================  */

    /**  6.1.门店要货申请列表  */
    getInventoryApplyListNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        ID: that.searchForm.ID,
        ProductName: that.searchForm.Name,
        EntityID: that.searchForm.EntityID,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
        BillStatus:
          that.searchForm.BillStatus == "0" ? "" : that.searchForm.BillStatus,
      };

      APIPSIApplyProduct.inventoryApplyList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.inventoryApplyList = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
            that.getInventoryProductBillStatusNumberNetwork();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /** 查询 产品 库存列表 列表  */
    get_stock_list_entityProductListNetwork: function (searchProductName) {
      var that = this;
      var params = {
        PageNum: that.productPageNum,
        ProductName: searchProductName,
        EntityID: that.entityApplyProduct.EntityID,
      };
      APIInventory.get_stock_list_entityProductListAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProductListTotal = res.Total;
            that.ProductList.push.apply(that.ProductList, res.List);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  4.4.仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.purchaseStorage = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  6.3.门店要货单申请  */
    setInventoryApplyCreateNetwork: function () {
      let that = this;
      let Products = Enumerable.from(that.entityApplyProduct.Product)
        .select((i) => ({
          ProductID: i.ProductID,
          UnitID: i.UnitID,
          ApplyQuantity: i.ApplyQuantity,
          MinimumUnitID: i.miniUnitID,
          ApplyMinimumUnitQuantity:
            Number(i.ApplyQuantity) * Number(i.miniAmount),
        }))
        .toArray();
      var params = {
        InboundEntityID: that.entityApplyProduct.EntityID,
        Remark: that.entityApplyProduct.Remark,
        InventoryApplyDetail: Products,
      };

      APIPSIApplyProduct.inventoryApplyCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "申请成功",
              duration: 2000,
            });
            that.dialogVisible = false;
            that.handleSearchEntityApplyProductClick();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**  6.2.门店要货单详情 */
    getinventoryApplyInfoNetwork: function (ID, type) {
      var that = this;
      var params = {
        ID: ID,
      };
      APIPSIApplyProduct.inventoryApplyInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.applyDetailInfo = res.Data;
            that.approveEntityRules = {
              EntityID: [
                {
                  required: true,
                  message: "请选择发货仓库",
                  trigger: ["blur", "change"],
                },
              ],
              ApproveQuantity: [
                { required: true, trigger: ["blur", "change"] },
              ],
              ApprovedPrice: [{ required: true, trigger: ["blur", "change"] }],
              OutboundQuantity: [
                { required: true, trigger: ["blur", "change"] },
              ],
              InboundQuantity: [
                { required: true, trigger: ["blur", "change"] },
              ],
            };
            switch (type) {
              case 0: // 查看详情
                that.applyDetaildialogVisible = true;
                break;
              case 1: // 审批详情
                that.OutboundEntitys = Enumerable.from(that.purchaseStorage)
                  .where((i) => i.ID != that.applyDetailInfo.InboundEntityID)
                  .toArray();
                that.applyDetailInfo.EntityID = "";
                that.applyDetailInfo.EntityName = "";
                that.approvalDetaildialogVisible = true;
                // that.$refs["approvalDetailRef"].clearValidate()
                that.applyDetailInfo.InDate = dateUtil.formatDate.format(
                  new Date(),
                  "YYYY-MM-DD hh:mm:ss"
                );
                break;

              case 2: // 发货详情
                that.outboundDetaildialogVisible = true;
                Enumerable.from(that.applyDetailInfo.Detail).forEach((i) => {
                  if (!i.OutboundIsLock) {
                    i.OutboundQuantity = i.StockQuantity > i.ApproveQuantity ? i.ApproveQuantity:i.StockQuantity;
                    i.OutboundPrice = i.ApprovedPrice;
                    i.OutboundTotalAmount = parseFloat(i.OutboundQuantity) *parseFloat(i.OutboundPrice);
                  }
                });
                break;

              case 3: // 入库详情
                that.inboundDetaildialogVisible = true;
                Enumerable.from(that.applyDetailInfo.Detail).forEach((i) => {
                  if(!i.InboundIsLock){
                    i.InboundQuantity = i.OutboundQuantity;
                    i.InboundPrice = i.OutboundPrice;
                    i.InboundTotalAmount = parseFloat(i.InboundQuantity) * parseFloat(i.InboundPrice);
                  }
                });

                break;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  6.4.门店要货单审批*/
    setinventoryApplyApprovedNetwork: function (params) {
      var that = this;
      APIPSIApplyProduct.inventoryApplyApproved(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "审核成功",
              duration: 2000,
            });
            that.handleSearchEntityApplyProductClick();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.approvedPaasLoading = false;
          that.approvedRefuseLoading = false;
          that.approvalDetaildialogVisible = false;
          that.finalRejectionDialogVisible = false;
        });
    },
    /**  6.5.门店要货单配送出库 */
    setinventoryApplyOutboundNetwork: function (params) {
      var that = this;
      APIPSIApplyProduct.inventoryProductOutbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "审核成功",
              duration: 2000,
            });
            that.outboundDetaildialogVisible = false;
            that.handleSearchEntityApplyProductClick();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.finalRejectionDialogVisible = false;
        });
    },

    /**  6.6.门店要货单入库  */
    setinventoryApplyInboundNetwork: function (params) {
      var that = this;
      APIPSIApplyProduct.inventoryProductInbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "审核成功",
              duration: 2000,
            });
            that.inboundDetaildialogVisible = false;
            that.handleSearchEntityApplyProductClick();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**  6.7.门店要货单取消 */
    setInventoryProductCancelNetwork: function (ID) {
      var that = this;
      that.approvedLoading = true;
      var params = {
        ID: ID,
      };
      APIPSIApplyProduct.inventoryProductCancel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message({
              message: "取消成功",
              duration: 2000,
            });
            that.handleSearchEntityApplyProductClick();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  6.7.门店要货单取消 */
    setinventoryProductCancelRejectApplylNetwork: function (ID) {
      var that = this;
      that.approvedLoading = true;
      var params = {
        ID: ID,
      };
      APIPSIApplyProduct.inventoryProductCancelRejectApply(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message({
              message: "取消成功",
              duration: 2000,
            });
            that.handleSearchEntityApplyProductClick();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /** 6.8.门店要货单状态统计 */
    getInventoryProductBillStatusNumberNetwork: function (ID) {
      var that = this;
      that.approvedLoading = true;
      var params = {
        ID: that.searchForm.ID,
        ProductName: that.searchForm.Name,
        EntityID: that.searchForm.EntityID,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
        // BillStatus:
        //   that.searchForm.BillStatus == "0" ? "" : that.searchForm.BillStatus,
      };

      APIPSIApplyProduct.inventoryProductBillStatusNumber(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.StatusNumberInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /** 门店要货单审批 产品实时库存  */
    getinventoryProductStockNetwork: function (ProductIDs, EntityID) {
      var that = this;
      var params = {
        ProductID: ProductIDs,
        EntityID: EntityID,
      };
      APIPSIApplyProduct.inventoryProductStock(params)
        .then((res) => {
          if (res.StateCode == 200) {
            let Products = res.Data;
            that.applyDetailInfo.Detail = Enumerable.from(
              that.applyDetailInfo.Detail
            )
              .select((val) => {
                val.ApproveQuantity = "";
                return val;
              })
              .toArray();
            Enumerable.from(that.applyDetailInfo.Detail).forEach((i) => {
              i.ApproveQuantity = "";
              let tempProduct = Enumerable.from(Products).singleOrDefault((j) => {return j.ProductID == i.ProductID;},-1);
              if (tempProduct != -1) {
                i.StockQuantity = tempProduct.Quantity; // 可用库存  最小单位数量
                i.miniUnitName = tempProduct.UnitName; // 最小单位名称
                i.miniUnitID = tempProduct.UnitID; // 最小单位 ID

                let totalQuantity = Enumerable.from(that.applyDetailInfo.Detail).where((val) => {return val.ProductID == i.ProductID;})
                  .sum((val) => Number(val.ApproveQuantity || 0) * Number(val.MinimumUnitAmount));

                // 最小单位剩余数量
                tempProduct.balanceQuantity =
                  parseFloat(tempProduct.Quantity) -
                  parseFloat(totalQuantity || 0);
                // 当前大单位的数量
                let tempApproveQuantity = Math.floor(
                  parseFloat(tempProduct.balanceQuantity) /
                    parseFloat(i.MinimumUnitAmount)
                );
                i.ApproveQuantity =
                  i.ApplyQuantity > tempApproveQuantity
                    ? tempApproveQuantity
                    : i.ApplyQuantity;

                // 计算价格
                if (i.ApprovedPrice) {
                  i.ApprovedTotalAmount =
                    parseFloat(i.ApproveQuantity) *
                    parseFloat(i.MinimumUnitAmount) *
                    parseFloat(i.ApprovedPrice);
                }
              }
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**   10.2.产品入库详情    */
    get_info_inventoryProductInbound_netWork: function (params) {
      var that = this;
      that.loading = true;
      APIInbound.get_info_inventoryProductInbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.InboundInfo = res.Data;
            that.InboundInfoDialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  8.2.产品出库详情   */
    get_info_ProductInventoryOutbound_netWork: function (params) {
      var that = this;
      that.loading = true;
      APIOutbound.getProductInventoryOutbound_info(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.OutboundInfoDialogVisible = true;
            that.OutboundInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
         /**  新建要货  */
      that.isAdd = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Add"
      );
      /**  审核单据  */
      that.isCheck = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Check"
      );
      /** 关闭待审核单据 */
      that.isClose = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Close"
      );
      /** 关闭已驳回单据 */
      that.isTurnClose = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-RejectClose"
      );
      /**  配送出库 */
      that.isDelivery = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Delivery"
      );
      /**  要货入库 */
      that.isStorage = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-Storage"
      );
      /**  查看配送出库单 */
      that.isViewDeliveryBill = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-ViewDeliveryBill"
      );
      /**  查看要货入库单  */
      that.isViewStorageyBill = permission.permission(
        that.$route.meta.Permission,
        "PSI-Purchase-EntityApplyProduct-ViewStorageyBill"
      );
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchForm.DateTime = [
    //   dateUtil.formatDate.format(new Date(y, m, 1), "YYYY-MM-DD"),
    //   dateUtil.formatDate.format(new Date(), "YYYY-MM-DD"),
    // ];
    that.handleSearchEntityApplyProductClick();
    that.getStorageEntityNetwork();
    // that.get_stock_list_entityProductListNetwork();
  },
};
</script>

<style lang="scss" >
.EntityApplyProduct {
  .entityApplyProductDialogClass {
    .entityApplyProductInfoFrom {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
      .el-input__inner {
        padding-right: 0;
      }
    }
    .productFormInforClass {
      .el-form-item {
        margin-bottom: 0px;
      }
    }
  }

  .IsLockProduct_list_back {
    background-color: #edf2fc;
    cursor: not-allowed;
  }
  .IsLockProduct_list_back:hover > td {
    background-color: #edf2fc !important;
  }
  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-tabs--border-card {
    // border: 0px,0px,0px,0px !important;
    border-bottom: 0px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0 0px 0 rgba(0, 0, 0, 0);
    .el-tabs__content {
      padding: 0px !important;
    }
  }
}
.EntityApplyProduct_custom_popper_class {
  .el-select-dropdown__item {
    line-height: normal;
    height: auto;
  }
}
</style>
