/**
 * Created by preference on 2020/11/20
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**  6.1.门店要货申请列表 */
  inventoryApplyList: params => {
    return API.POST('api/inventoryApply/list', params)
  },
  /**  6.2.门店要货单详情 */
  inventoryApplyInfo: params => {
    return API.POST('api/inventoryApply/info', params)
  },
  /**  6.3.门店要货单申请 */
  inventoryApplyCreate: params => {
    return API.POST('api/inventoryApply/create', params)
  },
  /** 6.4.门店要货单审批  */
  inventoryApplyApproved: params => {
    return API.POST('api/inventoryApply/approved', params)
  },
  /** 门店要货单审批 产品实时库存  */
  inventoryProductStock: params => {
    return API.POST('api/stock/productStock', params)
  },
  /** 6.5.门店要货单配送出库  */
  inventoryProductOutbound: params => {
    return API.POST('api/inventoryApply/outbound', params)
  },
  /** 6.6.门店要货单入库  */
  inventoryProductInbound: params => {
    return API.POST('api/inventoryApply/inbound', params)
  },
  /** 6.7.门店要货单取消  */
  inventoryProductCancel: params => {
    return API.POST('api/inventoryApply/cancel', params)
  },

  /** 6.8.门店要货单状态统计  */
  inventoryProductBillStatusNumber: params => {
    return API.POST('api/inventoryApply/billStatusNumber', params)
  },

  /** 6.8.门店要货单 驳回 关闭  */
  inventoryProductCancelRejectApply: params => {
    return API.POST('api/inventoryApply/cancelRejectApply', params)
  },

  
}